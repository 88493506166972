/* stylelint-disable selector-class-pattern */
@import '@/modules/ui/utils/mixins';

.dialog {
  @include medium-up {
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.MuiDialog-paperWidthSm) {
      width: px-to-rem(500px);
    }
  }
}

.closeButton {
  position: absolute;
  top: px-to-rem(6px);
  right: px-to-rem(16px);
}

.priceCaption {
  margin-top: px-to-rem(12px);

  color: #929dad;
  font-size: px-to-rem(15px);
  line-height: 1;
}

.divider {
  display: flex;
  gap: px-to-rem(10px);
  align-items: center;

  margin: px-to-rem(20px) 0;

  &::before,
  &::after {
    content: '';

    flex: 1;

    height: px-to-rem(2px);

    background-color: #f7f7f7;
  }
}
